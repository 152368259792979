/* src/Testimonials.css */
.testimonials {
    padding: 50px 20px;
    background: linear-gradient(135deg, #E5DFD1 0%, #E5DFD1 50%, var(--secondary-color) 100%);
    text-align: center;
}

.testimonials-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: var(--primary-color);
}

.testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.testimonial {
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.testimonial:hover {
    transform: scale(1.05);
}

.testimonial-text {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: var(--text-color);
}

.testimonial-author {
    font-size: 1rem;
    color: var(--text-color);
}
