/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
    --background-color: #E5DFD1; /* Slightly darker beige background color */
    --primary-color: #3C6E47; /* Dark green color */
    --secondary-color: #E6F4EA; /* Light green color */
    --hover-color: #6E9E78; /* Medium green color */
    --text-color: #3C6E47; /* Dark green text color */
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    line-height: 1.6;
    overflow-x: hidden;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: var(--secondary-color);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.logo {
    max-width: 150px;
}

.nav-list {
    list-style: none;
    display: flex;
}

.nav-list .nav-link {
    margin-right: 20px;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.3s;
    font-size: 1rem;
}

.nav-list .nav-link:hover {
    color: var(--hover-color);
}

.hero {
    background: linear-gradient(135deg, var(--secondary-color) 0%, var(--background-color) 100%);
    text-align: center;
    padding: 150px 20px;
    color: var(--text-color);
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 40px;
}

.cta-button {
    background: var(--primary-color);
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 50px;
    transition: background 0.3s, transform 0.3s;
    display: inline-block;
    font-size: 1rem;
}

.cta-button:hover {
    background: var(--hover-color);
    transform: scale(1.05);
}

.features, .testimonials, .cta, .about-us, .contact-us, .create-card, .donate {
    padding: 50px 20px;
    text-align: center;
    background: var(--background-color);
    color: var(--text-color);
}

.features-title, .testimonials-title, .cta-title, .about-us-title, .contact-us-title, .create-card-title, .donate-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: var(--text-color);
}

.features-container, .testimonials-container, .team-container, .locations-container, .nonprofit-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.feature, .testimonial, .team-member, .location-card, .nonprofit-item {
    background: var(--secondary-color);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.feature:hover, .testimonial:hover, .team-member:hover, .location-card:hover, .nonprofit-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.feature-title, .testimonial-author, .member-name, .location-name, .nonprofit-link {
    font-size: 1.5rem;
    color: var(--text-color);
}

.feature-description, .testimonial-text, .member-position, .location-address {
    font-size: 1rem;
}

.footer {
    background: var(--secondary-color);
    text-align: center;
    padding: 20px;
    color: var(--text-color);
}

.social-links {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 20px 0;
}

.social-link {
    margin: 0 10px;
    color: var(--text-color);
    text-decoration: none;
    transition: color 0.3s;
}

.social-link:hover {
    color: var(--hover-color);
}

.contact-info, .copyright {
    margin: 10px 0;
}
