/* src/ContactUs.css */
.contact-us {
    padding: 50px 20px;
    text-align: center;
    background: var(--background-color);
    color: var(--text-color);
}

.contact-us-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: var(--text-color);
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: var(--text-color);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--text-color);
    border-radius: 5px;
    background: var(--background-color);
    color: var(--text-color);
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: var(--text-color);
    outline: none;
}

.submit-button {
    background: var(--primary-color);
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.submit-button:hover {
    background: var(--hover-color);
}
