/* src/AboutUs.css */
.about-us {
    padding: 100px 20px; /* Existing padding */
    margin-top: 70px; /* Add margin-top to create space below the fixed header */
    text-align: center;
    background: var(--background-color);
    color: var(--text-color);
}

.about-us-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: var(--text-color);
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.team-member {
    background: var(--secondary-color);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.member-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid var(--text-color);
}

.member-name {
    font-size: 1.5rem;
    color: var(--text-color);
}

.member-position {
    font-size: 1rem;
    color: var(--text-color);
}
