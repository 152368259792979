/* src/CallToAction.css */
.cta {
    padding: 50px 20px;
    background: var(--primary-color);
    color: white;
    text-align: center;
}

.cta-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: white; /* Ensure the text color is white for contrast */
}

.cta-subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: white; /* Ensure the text color is white for contrast */
}

.cta-button {
    background: white;
    color: var(--primary-color);
    padding: 15px 30px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    text-decoration: none;
}

.cta-button:hover {
    background: var(--hover-color);
    color: white;
}
