/* src/Hero.css */
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    text-align: center;
}

.hero-content {
    max-width: 600px;
    padding: 20px;
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.hero-button {
    background: white;
    color: var(--primary-color);
    padding: 15px 30px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.hero-button:hover {
    background: var(--hover-color);
    color: white;
}
