/* src/ChildCardDesigns.css */
.child-card-designs {
    padding: 100px 20px;
    text-align: center;
    background: var(--background-color);
    color: var(--text-color);
}

.title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: var(--text-color);
}

.designs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.design-card {
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 200px;
}

.design-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.design-name {
    margin: 15px 0;
    font-size: 1.2rem;
    color: var(--text-color);
}

.download-button {
    background: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
}

.download-button:hover {
    background: var(--hover-color);
    transform: scale(1.05);
}

.download-count {
    margin-top: 10px;
    font-size: 0.9rem;
    color: var(--text-color);
}
.designs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.design-card {
    background: var(--secondary-color);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: calc(50% - 20px); /* 50% width on tablet screens */
    max-width: 200px;
    flex-grow: 1; /* Allow cards to grow */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .design-card {
        width: 100%; /* Full width on mobile */
    }
}
