/* src/Header.css */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: var(--secondary-color);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.logo {
    max-width: 65px;
}

.nav-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex: 1;
}

.nav-list .nav-link {
    margin: 0 15px;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.3s;
    font-size: 0.9rem;
    font-weight: 500;
}

.nav-list .nav-link:hover {
    color: var(--hover-color);
}

/* Hide hamburger by default */
.hamburger {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .nav-list {
        display: none; /* Hide the navigation list by default on mobile */
        position: absolute;
        top: 60px;
        right: 20px;
        background-color: var(--secondary-color);
        flex-direction: column;
        gap: 15px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .nav-list.mobile-menu-open {
        display: flex; /* Show the navigation list when the menu is open */
    }

    .hamburger {
        display: block; /* Show the hamburger icon on mobile */
    }
}
