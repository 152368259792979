/* src/Donate.css */
.donate {
    padding: 50px 20px;
    text-align: center;
    background: var(--background-color);
    color: var(--text-color);
}

.donate-title {
    font-size: 3rem;
    margin-bottom: 20px;
    color: var(--text-color);
}

.donate-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
}

.locations-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.location-card {
    background: var(--secondary-color);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.location-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.location-name {
    font-size: 1.5rem;
    color: var(--text-color);
    margin-bottom: 10px;
}

.location-address {
    font-size: 1rem;
    color: var(--text-color);
}

.nonprofit-container {
    margin-top: 40px;
}

.nonprofit-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--text-color);
}

.nonprofit-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.nonprofit-item {
    background: var(--secondary-color);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.nonprofit-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.nonprofit-link {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s;
}

.nonprofit-link:hover {
    color: var(--hover-color);
}
