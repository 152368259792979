/* src/Footer.css */
.footer {
    background: var(--secondary-color);
    text-align: center;
    padding: 20px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.social-links {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 20px 0;
    flex-wrap: wrap;  /* Allow the social links to wrap on small screens */
}

.social-link {
    margin: 0 15px;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s;
}

.social-link:hover {
    color: var(--hover-color);
}

.contact-info, .copyright {
    margin: 10px 0;
    font-size: 0.9rem;
    color: var(--text-color);
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .footer {
        padding: 15px;  /* Reduce padding on mobile */
        font-size: 0.9rem;  /* Slightly smaller font size */
    }

    .social-links {
        flex-direction: column;  /* Stack social links vertically on mobile */
        margin: 10px 0;  /* Adjust margin */
    }

    .social-link {
        margin: 10px 0;  /* Adjust margin between social links */
        font-size: 1rem;  /* Ensure the font size is still readable */
    }

    .contact-info, .copyright {
        font-size: 0.8rem;  /* Smaller font size for mobile */
        margin: 5px 0;  /* Reduce margin */
    }
}
